import React, { useState, useContext } from "react";
import ModalPatientStudy from "../../Modal/ModalPatientStudy";
import ViewOnlyRow from "../ViewOnlyForms/ViewOnlyRow";
import ObjHook from "../../../hooks/ObjHook";
import { patientStudyTemplate } from "../../../assets/Utils_DataTemplate";
import { AuthContext } from "../../../auth/useAuthContext";

export default ({ patientInfo }) => {
  const { preloadedData } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [patientEdit, formSetPatientEdit, setPatientEdit] =
    ObjHook(patientStudyTemplate);

  return (
    <div>
      {isOpen && (
        <ModalPatientStudy
          open={isOpen}
          setOpen={setIsOpen}
          patientId={patientInfo.id}
          study={patientEdit}
          setStudy={formSetPatientEdit}
          resetStudy={setPatientEdit}
        />
      )}
      {!isOpen && (
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div className="flex justify-between">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                <strong>Studies Information</strong>
              </h3>
              {patientInfo.id && preloadedData.length > 0 && (
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  className="inline-flex justify-center w-1/2 rounded-md border border-transparent bg-yellow-600 p-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                >
                  Add
                </button>
              )}
            </div>
            {patientInfo.id && (
              <div className="border-t border-gray-200">
                <dl>
                  {patientInfo.studies && (
                    <ViewOnlyRow list={patientInfo.studies} white study />
                  )}
                  {!patientInfo.studies && <h1>This patient has no studies</h1>}
                </dl>
              </div>
            )}

            {!patientInfo.id && (
              <div className="border-t border-gray-200">
                <h1 className="text-red-700">
                  <strong>
                    A study cannot be added for this patient because it is not
                    saved in the Database. To add a new study for this patient
                    please add the patient to the Database first..
                  </strong>
                </h1>
              </div>
            )}

            {preloadedData.length <= 0 && (
              <h1 className="text-red-700">
                <strong>
                  A new study cannot be added for this patient because there are
                  no predefine studies on the database. Please add a study on
                  the database first.
                </strong>
              </h1>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
