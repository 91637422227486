import React, { useState } from "react";
import {
  isValidEmail,
  isValidName,
  isValidSSN,
  isValidID,
  isValidZIP,
  isValidAddress,
  isValidUSPhoneNumber,
  isGreaterThan5,
  isLessThan500,
} from "../assets/Validator";

export default (initialState) => {
  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    const value = e.target.value ? e.target.value : "";

    let updatedValue = {};
    switch (e.target.name) {
      case "email": {
        updatedValue = { [e.target.name]: isValidEmail(value) };
        break;
      }
      case "ssn": {
        updatedValue = { [e.target.name]: isValidSSN(value) };
        break;
      }
      case "valid_id": {
        updatedValue = { [e.target.name]: isValidID(value) };
        break;
      }
      case "zip": {
        updatedValue = { [e.target.name]: isValidZIP(value) };
        break;
      }
      case "address1": {
        updatedValue = { [e.target.name]: isValidAddress(value) };
        break;
      }
      case "phone" || "emergecnyContactPhone": {
        updatedValue = { [e.target.name]: isValidUSPhoneNumber(value) };
        break;
      }
      case "dob": {
        updatedValue = { [e.target.name]: isGreaterThan5(value) };
        break;
      }
      case "otherPatientNotes": {
        updatedValue = { [e.target.name]: isLessThan500(value) };
        break;
      }
      default:
        updatedValue = { [e.target.name]: isValidName(value) };
    }

    setState((state) => ({
      ...state,
      ...updatedValue,
    }));
  };

  const removeState = (e) => {
    let copyOfObject = { ...state };
    delete copyOfObject[e.target.value];

    setState((copyOfObject) => ({
      ...copyOfObject,
    }));
  };

  const resetState = (newS) => {
    setState(newS);
  };

  return [state, handleChange, resetState, removeState];
};
