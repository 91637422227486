import React, { useContext, useEffect, useState } from "react";

import {
  vaccineType,
  studyResult,
  patientTemplateValidator,
  patientStudyTemplate,
} from "../../../assets/Utils_DataTemplate";
import axios from "axios";
import PatientFormValidator from "../../../hooks/PatientFormValidator";
import Loader from "../../LoaderOverlay/Loader";
import FormWrapper from "../FormWrapper";
import Datepicker from "../Datepicker";
import DropDownCustom from "../DropDownCustom";
import SingleTextarea from "../SingleTextarea";

import { AuthContext } from "../../../auth/useAuthContext";
import { validatePatientStudyData } from "../../../assets/ValidateSubmission";

export default ({
  patientId,
  setOpen,
  patientStudyData,
  resetPatientStudyData,
  setPatientStudyData,
}) => {
  const { isLoading, setIsLoading, preloadedData, setAlert } =
    useContext(AuthContext);

  const [validateForm] = PatientFormValidator(patientTemplateValidator);
  const [studyCode, setStudyCode] = useState({});
  const [custom_SID, setCustomSID] = useState("");
  const [sCode, setScode] = useState("");

  useEffect(() => {
    if (preloadedData.length > 0) setStudyCode(preloadedData[0]);

    if (!patientId) {
      setScode(patientStudyData.study_code);
      setCustomSID(patientStudyData.study_id_digits);
    } else setScode(preloadedData[0].name_code);
  }, [isLoading]);

  const onSubmitStudy = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const submit = {
      ...patientStudyData,
    };

    const id = studyCode.id + "-" + custom_SID;
    submit.study_id = id;
    submit.study_id_digits = custom_SID;
    submit.study_name = studyCode.name;
    submit.study_code = studyCode.name_code;
    submit.count = parseInt(studyCode.count) + 1;

    const err = validatePatientStudyData(submit);
    if (err.length > 0) {
      setAlert(err, true);
      setIsLoading(false);
      return;
    } else {
      try {
        if (patientStudyData && !patientId) {
          await axios.patch("/patient-studies/" + patientStudyData.id, submit);
        } else
          await axios.post("/patient-studies", {
            ...submit,
            patientId: patientId,
          });
        setOpen(false);
        resetPatientStudyData(patientStudyTemplate);
      } catch (err) {
        setAlert(
          "An Error has Occured When Saving new Study [0002]",
          false,
          err
        );
      }
    }
    setIsLoading(false);
  };

  const setCustomRecord = (e) => {
    const val = e.target.value;
    let t = preloadedData.find(
      (element) => element.name === val //patientStudyData.study_name
    );
    setStudyCode(t);
    setScode(t.name_code);
    setPatientStudyData(e);
  };

  //if (isLoading) return <Loader />;

  return (
    /* <h1>TEST</h1>*/
    <div className=" w-full">
      <form
        onSubmit={onSubmitStudy}
        className="sm:px-6 lg:col-span-9 lg:px-0  w-full"
      >
        {preloadedData.length <= 0 && (
          <div className="p-5">
            <h1 className="text-red-600 font-bold">
              A new study cannot be added for this patient because there are no
              predefine studies on the database. Please add a study on the
              database first.
            </h1>
          </div>
        )}

        {preloadedData.length > 0 && (
          <FormWrapper title="Add Study">
            <DropDownCustom
              label="Study Name"
              name="study_name"
              /*valid={validateForm.study_name}
              errMsg="You need to enter a valid First Name"*/
              value={patientId ? studyCode.name : patientStudyData.study_name}
              onChangeValue={setCustomRecord /*setPatientStudyData*/}
              arrVal={preloadedData}
              objVal="name"
              classNameDiv="col-span-12 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <TempInput
              label="Study Code"
              name="Study_Code"
              value={sCode}
              disabled
            />

            <TempInput
              label="Study ID"
              name="Patient_ID"
              value={custom_SID}
              onChange={(e) => setCustomSID(e.target.value)}
              maxLength={5}
            />
            <Datepicker
              label="Screening Date"
              name="screening_date"
              /*valid={validateForm.screening_date}
              errMsg="Please Select your a Date of Birth"*/
              value={patientStudyData.screening_date}
              onChangeValue={setPatientStudyData}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <Datepicker
              label="Date of Symptoms "
              name="symptoms_date"
              /*valid={validateForm.symptoms_date}
              errMsg="Please Select your a Date of Birth"*/
              value={patientStudyData.symptoms_date}
              onChangeValue={setPatientStudyData}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <DropDownCustom
              label="Study Result"
              name="study_result"
              /*valid={validateForm.study_result}
              errMsg="You need to enter a valid First Name"*/
              value={patientStudyData.study_result}
              onChangeValue={setPatientStudyData}
              arrVal={studyResult}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <DropDownCustom
              label="Vaccinated ?"
              name="isVaccinated"
              /*valid={validateForm.isVaccinated}
              errMsg="You need to enter a valid First Name"*/
              value={patientStudyData.isVaccinated}
              onChangeValue={setPatientStudyData}
              arrVal={["No", "Yes"]}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <DropDownCustom
              label="Vaccine Type"
              name="vaccineType"
              /*valid={validateForm.vaccineType}
              errMsg="You need to enter a valid First Name"*/
              value={patientStudyData.vaccineType}
              onChangeValue={setPatientStudyData}
              arrVal={vaccineType}
              disabled={patientStudyData.isVaccinated === "No"}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <DropDownCustom
              label="Number of Doses Taken"
              name="vaccineDosesTaken"
              /*valid={validateForm.vaccineDosesTaken}
              errMsg="You need to enter a valid First Name"*/
              value={patientStudyData.vaccineDosesTaken}
              onChangeValue={setPatientStudyData}
              arrVal={[...Array(10).keys()]}
              disabled={patientStudyData.isVaccinated === "No"}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <Datepicker
              label="Last Vaccine Date"
              name="lastVaccine"
              valid={validateForm.lastVaccine}
              errMsg="Please Select your a Date of Birth"
              value={patientStudyData.lastVaccine}
              disabled={patientStudyData.isVaccinated === "No"}
              onChangeValue={setPatientStudyData}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <DropDownCustom
              label="Visit Number"
              name="visitNumber"
              /* valid={validateForm.visitNumber}
              errMsg="You need to enter a valid First Name"*/
              value={patientStudyData.visitNumber}
              onChangeValue={setPatientStudyData}
              arrVal={[...Array(10).keys()]}
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <SingleTextarea
              label="Other Symptoms"
              name="otherSymptoms"
              /*valid={validateForm.otherSymptoms}
              errMsg="Please enter your comments in less than 500 characters"*/
              value={patientStudyData.otherSymptoms}
              onChangeValue={setPatientStudyData}
              placeholder="Enter your notes here..."
              maxLength={500}
            />
          </FormWrapper>
        )}
        {preloadedData.length > 0 && (
          <div className="shadow bg-white px-4 py-3 text-right sm:px-6 rounded mt-2">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save Study
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

const TempInput = ({ label, name, value, onChange, maxLength, disabled }) => {
  return (
    <div className="col-span-12 sm:col-span-2 ">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        value={value ? value : ""}
        onChange={onChange ? onChange : () => {}}
        type="text"
        className={`
          mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${
            disabled ? " bg-gray-200" : ""
          }
        `}
        maxLength={maxLength ? maxLength : 10}
        disabled={disabled ? disabled : false}
      />
    </div>
  );
};
