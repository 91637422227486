import React, { Fragment, useRef } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
export default ({ data, isArr, setOpen, open }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-1/4 relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-1/2 sm:mx-20 ">
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      {data && isArr && (
                        <h3 className="text-sm font-medium text-red-800">
                          Cannot save the entry because there are {data.length}{" "}
                          errors
                        </h3>
                      )}
                      <div className="mt-2 text-sm text-red-700">
                        <div>
                          <ul role="list" className="list-disc space-y-1 pl-5">
                            {data &&
                              isArr &&
                              data.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            {!isArr && <p className="font-bold">{data}</p>}
                          </ul>
                        </div>

                        <div className="mt-5">
                          <div className="-mx-2 -my-1.5 flex mt-4">
                            <button
                              onClick={() => setOpen(false)}
                              type="button"
                              className="ml-3 rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                            >
                              Dismiss
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
