import React, { useContext } from "react";
import SingleInput from "../Forms/SingleInput";
import DropDownCustom from "../Forms/DropDownCustom";
import { AuthContext } from "../../auth/useAuthContext";
import Loader from "../LoaderOverlay/Loader";

export default ({ filters, setFilters, setFiltersSID }) => {
  const { isLoading, preloadedData } = useContext(AuthContext);

  const handleStudyNameChange = (e) => {
    const val = e.target.value;
    const SID = preloadedData.find((p) => p.name === val);
    setFiltersSID(SID.name_code);
    setFilters(e);
  };

  if (isLoading) return <Loader />;

  if (preloadedData.length > 0)
    return (
      <div className="grid grid-cols-6 gap-6">
        <div className="sm:col-span-6">
          <h1>Filters</h1>
          <hr />
        </div>
        <DropDownCustom
          label="Study Name"
          name="study_name"
          value={filters.study_name}
          onChangeValue={handleStudyNameChange}
          arrVal={[{ name: "Any", study_name: "Any" }, ...preloadedData]}
          objVal="name"
          classNameDiv="sm:col-span-2"
          classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
        <SingleInput
          placeholder="Age Minimum (Any)"
          label="From"
          name="fromAge"
          value={filters.fromAge}
          onChangeValue={setFilters}
          classNameDiv=" sm:col-span-2"
          classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          maxLength={3}
        />
        <SingleInput
          placeholder="Age Maximum (Any)"
          label="To"
          name="toAge"
          value={filters.toAge}
          onChangeValue={setFilters}
          classNameDiv=" sm:col-span-2"
          classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          maxLength={3}
        />
        <DropDownCustom
          label="Study Result"
          name="studyResult"
          value={filters.studyResult}
          onChangeValue={setFilters}
          arrVal={["Any", "Positive", "Negative", "Invalid"]}
          classNameDiv="sm:col-span-2"
          classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
        <SingleInput
          placeholder="Medication (Any)"
          label="Medication"
          name="medication"
          value={filters.medication}
          onChangeValue={setFilters}
          classNameDiv=" sm:col-span-2"
          classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          maxLength={30}
        />
        <SingleInput
          placeholder="Medical Condition (Any)"
          label="Medical Condition"
          name="med_condition"
          value={filters.med_condition}
          onChangeValue={setFilters}
          classNameDiv=" sm:col-span-2"
          classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          maxLength={30}
        />
        <div className="sm:col-span-6 mb-5">
          <hr />
        </div>
      </div>
    );
};
