import React, { useState } from "react";
import DecodeDriversLicense_FL from "../../../assets/decoders/FloridaDiverLicense";
import SingleTextarea from "../SingleTextarea";

const ScanPatientProfile = ({ setPatient, patient }) => {
  const [state, setState] = useState("");

  let timer;
  const onChangeValue = (e) => {
    setState(e.target.value);

    clearTimeout(timer);
    timer = setTimeout(() => {
      const decoded = DecodeDriversLicense_FL(state);
      setPatient({ ...patient, ...decoded });
    }, 2000);
  };

  return (
    <SingleTextarea
      name="scannedData"
      value={state}
      onChangeValue={onChangeValue}
      maxLength={500}
      placeholder="Scan DR Licenses Data"
      label="FOR SCANNER USE ONLY"
    />
  );
};

export default ScanPatientProfile;
