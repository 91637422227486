import React, { useContext } from "react";

import ObjHook from "../../../hooks/ObjHook";
import {
  studyTemplate,
  studyTemplateValidator,
  trueFalse,
} from "../../../assets/Utils_DataTemplate";
import { AuthContext } from "../../../auth/useAuthContext";
import PatientFormValidator from "../../../hooks/PatientFormValidator";
import Loader from "../../LoaderOverlay/Loader";
import FormWrapper from "../FormWrapper";
import SingleInput from "../SingleInput";
import DropDownCustom from "../DropDownCustom";
import axios from "axios";
import { validateStudy } from "../../../assets/ValidateSubmission";

export default () => {
  const { isLoading, setIsLoading, setAlert } = useContext(AuthContext);
  const [studyData, setStudyData, resetStudyData] = ObjHook(studyTemplate);
  const [validateForm] = PatientFormValidator(studyTemplateValidator);

  const submitStudy = (e) => {
    e.preventDefault();
    async function submit() {
      setIsLoading(true);
      let tempObj = {
        ...studyData,
        visible: studyData.visible === "Yes" ? true : false,
      };
      const err = validateStudy(tempObj);
      if (err.length > 0) {
        setAlert(err, true);
        setIsLoading(false);
        return;
      } else {
        try {
          await axios.post("./study", tempObj);
        } catch (err) {
          setAlert(
            "An Error has Occurred when posing study [0001]",
            false,
            err
          );
          setIsLoading(false);
        }
      }
      resetStudyData(studyTemplate);
      setIsLoading(false);
    }
    submit();
  };

  /*  onkeydown = (event) => {
    if (event.key === "Enter") submitStudy();
  };*/

  return (
    <form
      onSubmit={submitStudy}
      className="lg:grid lg:grid-cols-12 lg:gap-x-5 mt-10 mb-5"
    >
      <div className="lg:col-span-12 w-full ">
        {/*{isLoading && <Loader active={isLoading} />}*/}

        <FormWrapper title="Create a Study">
          <SingleInput
            label="Study Name"
            name="name"
            placeholder="Study Name"
            valid={studyData.name}
            errMsg="SSN is Not Valid"
            value={studyData.name}
            onChangeValue={setStudyData}
            classNameDiv="col-span-6 sm:col-span-2"
            maxLength={15}
          />
          <SingleInput
            label="Name Code in the Format (XXX)"
            name="name_code"
            placeholder="XXX"
            valid={studyData.name_code}
            errMsg="SSN is Not Valid"
            value={studyData.name_code}
            onChangeValue={setStudyData}
            classNameDiv="col-span-6 sm:col-span-2"
            maxLength={5}
          />
          <DropDownCustom
            label="Visible?"
            name="visible"
            valid={validateForm.visible}
            value={studyData.visible}
            onChangeValue={setStudyData}
            arrVal={trueFalse}
            classNameDiv="col-span-6 sm:col-span-2"
            classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </FormWrapper>

        <div className="shadow bg-white px-4 py-3 text-right sm:px-6 rounded mt-2">
          <button
            type="submit"
            onClick={submitStudy}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
