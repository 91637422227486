export default ({ value, onChangeValue }) => {
  const magnifierIco = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.3}
      stroke="#c9c9c9"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
      />
    </svg>
  );
  return (
    <div className="">
      <div className="relative mt-1 flex items-center ">
        <input
          value={value}
          onChange={onChangeValue}
          type="text"
          name="search"
          id="search"
          className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {value.length <= 0 && (
          <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5 grayscale px-2">
            {magnifierIco} <p className="text-gray-400"> Search</p>
          </div>
        )}
      </div>
    </div>
  );
};
