import React from "react";

export default ({
  person,
  setSelectedPeople,
  selectedPeople,
  onPatientEdit,
  classNames,
  onPatientView,
}) => {
  return (
    <tr className={selectedPeople.includes(person) ? "bg-gray-50" : undefined}>
      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
        {selectedPeople.includes(person) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          value={person.id}
          checked={selectedPeople.includes(person)}
          onChange={(e) =>
            setSelectedPeople(
              e.target.checked
                ? [...selectedPeople, person]
                : selectedPeople.filter((p) => p !== person)
            )
          }
        />
      </td>
      <td
        className={classNames(
          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
          selectedPeople.includes(person) ? "text-indigo-600" : "text-gray-900"
        )}
      >
        {person.id}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.study_name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.visitNumber}
      </td>
      <td
        className={`whitespace-nowrap px-3 py-4 text-sm text-${
          person.study_result === "Positive"
            ? "red"
            : person.study_result === "Negative"
            ? "green"
            : "yellow"
        }-500`}
      >
        <strong> {person.study_result}</strong>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.symptoms_date}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.screening_date}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.isVaccinated}
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          onClick={onPatientEdit}
          className="text-indigo-600 hover:text-indigo-900 ml-2"
          value={person.id}
        >
          Edit
          <span className="sr-only">, {person.name}</span>
        </button>
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          onClick={onPatientView}
          className="text-indigo-600 hover:text-indigo-900 mr-2"
          value={person.id}
        >
          View
          <span className="sr-only">, {person.name}</span>
        </button>
      </td>
    </tr>
  );
};
