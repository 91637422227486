import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";
import IndexStudiesForm from "../../Forms/StudiesForm/IndexStudiesForm";
import SearchInputForm from "../SearchInputForm";
import TableRow from "./TableRow";
import Loader from "../../LoaderOverlay/Loader";
import axios from "axios";
import HeaderTitle from "../../HeaderTitle";
import { AuthContext } from "../../../auth/useAuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default () => {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);

  const [selectedPeople, setSelectedPeople] = useState([]);

  //To Setup a new Patient State
  const [people, setPeople] = useState([]);
  const [peopleAPI, setPeopleAPI] = useState([]);

  //For Filtering Values
  const [searchValue, setSearchValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);

  const { isLoading, setIsLoading, setAlert } = useContext(AuthContext);

  //useLayoutEffect
  useLayoutEffect(() => {
    setChecked(people.length !== 0);
  }, []);

  useEffect(() => {
    async function getPatients() {
      try {
        const res = await axios.get("/study");
        setPeople(res.data);
        setPeopleAPI(res.data);
      } catch (err) {
        setAlert("An Error Has Ocurred [0017]", false, err);
      }
    }
    getPatients();
  }, [isLoading]);

  function toggleAll() {
    setSelectedPeople([]);
    let temp = people.map((item) => {
      if (!checked) item.visible = true;
      else item.visible = false;

      return item;
    });

    setSelectedPeople(temp);

    setChecked(!checked);
    setPeople(temp);
  }

  const onChangeSearchFilter = (e) => {
    if (e.target.value !== "") {
      setSearchValue(e.target.value);

      const filterTable = people.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setSearchValue(e.target.value);
      setPeople([...peopleAPI]);
    }
  };

  const onDelete = async () => {
    setIsLoading(true);

    try {
      await axios.delete("/study/disable", {
        data: selectedPeople,
      });
    } catch (err) {
      setAlert("ERROR WHEN DELETING A PATIENT [0018]", false, err);
    }
    setSelectedPeople([]);
    setIsLoading(false);
  };

  if (isLoading) return <Loader active />;

  return (
    <div className="12  w-2/3">
      <HeaderTitle title="Studies List" />
      <div className="  w-full">
        <div className="sm:flex-auto w-full">
          <div className="w-full ">
            <SearchInputForm
              onChangeValue={onChangeSearchFilter}
              value={searchValue}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedPeople.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    onClick={onDelete}
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Save
                  </button>
                </div>
              )}

              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <CustomTH
                    checkbox={checkbox}
                    checked={checked}
                    toggleAll={toggleAll}
                    values={["Name", "Code", "Count", "Visible"]}
                  />
                </thead>
                {!isLoading && people && peopleAPI && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {searchValue.length > 0
                      ? tableFilter.map((person, index) => (
                          <TableRow
                            key={index}
                            person={person}
                            index={index}
                            setPeople={setPeople}
                            selectedPeople={selectedPeople}
                            setSelectedPeople={setSelectedPeople}
                            people={people}
                            classNames={classNames}
                          />
                        ))
                      : people.map((person, index) => (
                          <TableRow
                            key={index}
                            person={person}
                            index={index}
                            setPeople={setPeople}
                            people={people}
                            selectedPeople={selectedPeople}
                            setSelectedPeople={setSelectedPeople}
                            classNames={classNames}
                          />
                        ))}
                  </tbody>
                )}
              </table>
              {(isLoading || people.length <= 0 || peopleAPI.length <= 0) && (
                <div className="w-100 flex justify-center">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <IndexStudiesForm />
    </div>
  );
};

const CustomTH = ({ checkbox, checked, toggleAll, values }) => {
  return (
    <tr>
      <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          ref={checkbox}
          checked={checked}
          onChange={toggleAll}
        />
      </th>

      {values.map((item, index) => (
        <th
          key={index}
          scope="col"
          className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
        >
          {item}
        </th>
      ))}
    </tr>
  );
};
