import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default ({
  classNameDiv,
  classNameInput,
  label,
  onChangeValue,
  name,
  placeholder,
  valid,
  value,
  errMsg,
  maxLength,
}) => {
  const idSalt = "-xhxqo";
  const classNameD = classNameDiv ? classNameDiv : "col-span-6 sm:col-span-6";
  const defClassName = classNameInput
    ? classNameInput
    : "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
  const errClassName =
    "focus:ring-red-500 text-red-900 placeholder-red-300  focus:border-red-500 border-red-300";

  return (
    <div className={classNameD}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <textarea
        rows={5}
        name={name}
        id={label + idSalt}
        className={
          valid || value.length === 0
            ? defClassName
            : errClassName + " " + defClassName
        }
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder ? placeholder : ""}
        maxLength={maxLength ? maxLength : 200}
      />
      <div className="flex justify-end">
        <h6 className="text-gray-400">
          {value.length}/{maxLength}
        </h6>
      </div>{" "}
      {!valid && value.length !== 0 && (
        <div
          className="flex flex-row justify-center items-center mt-1"
          aria-hidden="true"
        >
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
          <p className=" text-sm text-red-600" id={label + "-error" + idSalt}>
            {errMsg}
          </p>
        </div>
      )}
    </div>
  );
};
