function DecodeDriversLicense_FL(s) {
  const arr = s.split("\n");
  let result = {};

  result.valid_id = DecodeInfo(arr, "ID");
  result.firstName = DecodeInfo(arr, "NAME");
  result.lastName = DecodeInfo(arr, "LAST");
  result.dob = DecodeInfo(arr, "DOB");
  result.address1 = DecodeInfo(arr, "ADD");
  result.city = DecodeInfo(arr, "CITY");
  result.state = DecodeInfo(arr, "STATE");
  result.zip = DecodeInfo(arr, "ZIP");
  result.gender = DecodeInfo(arr, "SEX");

  return result;
}

function DecodeInfo(arr, field) {
  switch (field) {
    case "ID": {
      const info = arr.find((e) => e.substring(0, 4) === "ANSI");
      return info ? info.substring(info.length - 13) : "";
    }
    case "NAME": {
      const info = arr.find((e) => e.substring(0, 3) === "DAC");
      return info ? info.substring(3) : "";
    }
    case "LAST": {
      const info = arr.find((e) => e.substring(0, 3) === "DCS");
      return info ? info.substring(3) : "";
    }
    case "DOB": {
      let info = arr.find((e) => e.substring(0, 3) === "DBB");
      info = info ? info.substring(3) : "";
      return info
        ? info.substring(4) +
            "-" +
            info.substring(0, 2) +
            "-" +
            info.substring(2, 4)
        : "";
    }
    case "ADD": {
      const info = arr.find((e) => e.substring(0, 3) === "DAG");
      return info ? info.substring(3) : "";
    }
    case "CITY": {
      const info = arr.find((e) => e.substring(0, 3) === "DAI");
      return info ? info.substring(3) : "";
    }
    case "STATE": {
      const info = arr.find((e) => e.substring(0, 3) === "DAJ");
      return info ? info.substring(3) : "";
    }
    case "ZIP": {
      const info = arr.find((e) => e.substring(0, 3) === "DAK");
      return info ? info.substring(3) : "";
    }
    case "SEX": {
      const info = arr.find((e) => e.substring(0, 3) === "DBC");
      const sex = info ? parseInt(info.substring(3)) : 9;
      return sex === 1 ? "Male" : sex === 2 ? "Female" : "Other";
    }
    default:
      return "";
  }
}

/*result.push(arr[3].substring(3)); //Last Name
result.push(arr[5].substring(3)); //First Name
result.push(arr[12].substring(3)); //Issue Date
result.push(arr[13].substring(3)); //DOB Date
result.push(arr[14].substring(3)); //Expiration Date
result.push(arr[17].substring(3)); //Address1
result.push(arr[18].substring(3)); //City
result.push(arr[19].substring(3)); //State
result.push(arr[20].substring(3)); //ZIP*/

export default DecodeDriversLicense_FL;
