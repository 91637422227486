import React from "react";

export default ({ title, children }) => {
  return (
    <div className="shadow bg-white  py-3 text-center sm:px-6 rounded my-5">
      <h1 style={{ fontSize: 25, fontWeight: 500, color: "#374151" }}>
        {title ? title : ""}
        {children ? children : ""}
      </h1>
    </div>
  );
};
