import React, { useState } from "react";
import ModalPatientStudyViewOnly from "../../Modal/ModalPatientStudyViewOnly";

export default ({ list }) => {
  const [open, setIsOpen] = useState(false);
  const [arrData, setArrData] = useState({});

  const tempList = list.map((item, index) => {
    let className = "bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500";
    if (item.study_result === "Positive")
      className = "bg-red-600 hover:bg-red-700 focus:ring-red-500";
    else if (item.study_result === "Negative")
      className = "bg-green-600 hover:bg-green-700 focus:ring-green-500";
    return (
      <button
        onClick={() => {
          setArrData(item);
          setIsOpen(true);
        }}
        value={index}
        key={index}
        className={
          "inline-flex justify-center rounded-md border border-transparent  p-1 mr-2 mt-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 " +
          className
        }
      >
        {item.id}
      </button>
    );
  });
  return (
    <div className="flex flex-wrap ">
      <ModalPatientStudyViewOnly
        setIsOpen={setIsOpen}
        open={open}
        data={arrData}
      />
      {tempList}
    </div>
  );
};
