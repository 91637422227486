import { ScaleLoader } from "react-spinners";
import "./Loader.css";
export default ({ active, text }) => {
  return (
    <div className={active ? "spinner" : "spinner-def"}>
      <ScaleLoader height={150} width={12} margin={12} color="black" />
      <h1 className="spinner-text">{text ? text : "Please Wait..."}</h1>
    </div>
  );
};
