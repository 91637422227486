import {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import SearchInputForm from "../SearchInputForm";
import PatientStudyTableRow from "./PatientStudyTableRow";
import "../../style.css";

import ObjHook from "../../../hooks/ObjHook";
import axios from "axios";
import Loader from "../../LoaderOverlay/Loader";
import ModalPatientStudy from "../../Modal/ModalPatientStudy";
import HeaderTitle from "../../HeaderTitle";
import ModalPatientStudyViewOnly from "../../Modal/ModalPatientStudyViewOnly";

import { AuthContext } from "../../../auth/useAuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default () => {
  //const dataSource = DummyData.dataSource;
  const { isLoading, setIsLoading, setAlert } = useContext(AuthContext);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  //To Setup a new Patient State
  const [people, setPeople] = useState([]);
  const [peopleAPI, setPeopleAPI] = useState([]);

  //For Filtering Values
  const [searchValue, setSearchValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);

  //To Togle Modal for Adding a Patient
  const [showPatientInfoModal, toggleShowPattientInfoModal] = useState(false);

  const [showPatientViewModal, toggleShowPatientViewModal] = useState(false);

  // For Editing
  const [patientEdit, formSetPatientEdit, setPatientEdit] = ObjHook({});

  //useLayoutEffect
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(
      selectedPeople.length === people.length && selectedPeople.length !== 0
    );
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);

  useEffect(() => {
    async function getPatients() {
      try {
        const res = await axios.get("/patient-studies");
        setPeople(res.data);
        setPeopleAPI(res.data);
      } catch (err) {
        setAlert("An Error Has Ocurred [0012]", false, err);
      }
    }
    getPatients();
  }, [isLoading]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const onChangeSearchFilter = (e) => {
    if (e.target.value !== "") {
      setSearchValue(e.target.value);

      const filterTable = people.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setSearchValue(e.target.value);
      if (peopleAPI) setPeople([...peopleAPI]);
    }
  };

  //Table Edit click (not Submit)
  const onPatientEdit = async (e) => {
    await onViewOrEdit(e);
    toggleShowPattientInfoModal(!showPatientInfoModal);
  };

  const onPatientView = async (e) => {
    await onViewOrEdit(e);
    toggleShowPatientViewModal(!showPatientViewModal);
  };

  const onViewOrEdit = async (e) => {
    setIsLoading(true);
    try {
      const id = e.target.value;
      const element = await axios.get("/patient-studies/" + id);

      const data = element.data;
      //TODO: Add Loader
      if (data) setPatientEdit(data);
      else setAlert("No Record Found [0014]");
    } catch (e) {
      setAlert("Error [0015]", false, e);
    }
    setIsLoading(false);
  };

  /*const onDelete = async () => {
    setIsLoading(true);
    try {
      await axios.delete("/patient-studies", {
        data: selectedPeople,
      });
    } catch (err) {
      setAlert("ERROR WHEN DELETING A PATIENT [0016]", false, err);
    }

    setIsLoading(false);
  };*/

  return (
    <div className="w-2/3">
      {showPatientInfoModal && (
        <ModalPatientStudy
          open={showPatientInfoModal}
          setOpen={toggleShowPattientInfoModal}
          study={patientEdit}
          setStudy={formSetPatientEdit}
          resetStudy={setPatientEdit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}

      <ModalPatientStudyViewOnly
        data={patientEdit}
        open={showPatientViewModal}
        setIsOpen={toggleShowPatientViewModal}
      />

      <HeaderTitle title="Patient Studies" />

      <div className="sm:flex sm:items-end   ">
        <div className="sm:flex-auto ">
          <div className="">
            <SearchInputForm
              onChangeValue={onChangeSearchFilter}
              value={searchValue}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-24">
              {/*{selectedPeople.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    onClick={onDelete}
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Delete all
                  </button>
                </div>
              )}*/}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Patient/Study ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Study Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Visit Number
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Study Result
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Symptoms Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Screening Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Is Vaccinated?
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {searchValue.length > 0
                    ? tableFilter.map((person, index) => (
                        <PatientStudyTableRow
                          key={index}
                          person={person}
                          setSelectedPeople={setSelectedPeople}
                          selectedPeople={selectedPeople}
                          onPatientEdit={onPatientEdit}
                          classNames={classNames}
                          onPatientView={onPatientView}
                        />
                      ))
                    : people.map((person, index) => (
                        <PatientStudyTableRow
                          key={index}
                          person={person}
                          setSelectedPeople={setSelectedPeople}
                          selectedPeople={selectedPeople}
                          onPatientEdit={onPatientEdit}
                          onPatientView={onPatientView}
                          classNames={classNames}
                        />
                      ))}
                </tbody>
              </table>
              {(isLoading || people.length <= 0 || peopleAPI.length <= 0) && (
                <div className="w-100 flex justify-center">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
