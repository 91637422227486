import React, { useState } from "react";

import ModalPlain from "../../Modal/ModalPlain";
import ViewRow from "./ViewRow";

export default ({ name, value, white, list, study }) => {
  return (
    <ViewRow
      name={name}
      value={value}
      white={white}
      list={list}
      study={study}
    />
  );
};

const TempModal = ({ open, setIsOpen, data }) => {
  return (
    <ModalPlain open={open} setOpen={setIsOpen}>
      <div className="overflow-hidden bg-white shadow-lg sm:rounded-lg ">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Patient Study Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            This information is strictly confidential. Do not share with
            non-authorized personal.
          </p>
        </div>

        <div className="border-t border-gray-200">
          <dl>
            <ViewRow name="Study ID" value={data.study_id} />
            <ViewRow name="Study Name" value={data.study_name} white />
            <ViewRow name="Screening Date" value={data.screening_date} />
            <ViewRow
              name="Symptoms Start Date"
              value={data.symptoms_date}
              white
            />
            <ViewRow name="Study Result" value={data.study_result} />
            <ViewRow name="Is Vaccinated?" value={data.isVaccinated} white />
            <ViewRow name="Vaccine Type" value={data.vaccineType} />
            <ViewRow name="Last Vaccine" value={data.lastVaccine} white />
            <ViewRow
              name="Vaccine Doses Taken"
              value={data.vaccineDosesTaken}
            />
            <ViewRow
              name="Visit Number"
              value={data.study_required_visits}
              white
            />
            <ViewRow name="Other Symptoms" value={data.otherSymptoms} />
          </dl>
        </div>
      </div>
    </ModalPlain>
  );
};
