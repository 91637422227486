import ViewOnlyRow from "./ViewOnlyRow";
export default ({ selectedPatient: val }) => {
  return (
    <div className="overflow-hidden bg-white shadow-lg sm:rounded-lg ">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Patient Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          This information is strictly confidential. Do not share with
          non-authorized personal.
        </p>
      </div>

      <div className="border-t border-gray-200">
        <dl>
          <ViewOnlyRow
            name="Name:"
            value={val.firstName + " " + val.lastName}
          />
          <ViewOnlyRow name="ID: " value={val.valid_id} white />
          <ViewOnlyRow name="ID Type:" value={val.idType} />
          <ViewOnlyRow name="Social Security:" value={val.ssn} white />
          <ViewOnlyRow name="Date of Birth:" value={val.dob} />
          <ViewOnlyRow
            name="Address:"
            value={
              val.address1 + ", " + val.city + ", " + val.state + ", " + val.zip
            }
            white
          />
          <ViewOnlyRow name="Phone:" value={val.phone} />
          <ViewOnlyRow name="Email:" value={val.email} white />
          <ViewOnlyRow name="Ethnicity:" value={val.ethnicity} />
          <ViewOnlyRow name="Race:" value={val.race} white />
          <ViewOnlyRow name="Gender:" value={val.gender} />
          <ViewOnlyRow
            name="Child Bearing Potential:"
            value={val.childBearingPotential}
            white
          />
          <ViewOnlyRow name="Reason (If Applicable):" value={val.reasonOfNo} />
          <ViewOnlyRow
            name="Emergency Contact Name:"
            value={val.emergecnyContactName}
            white
          />
          <ViewOnlyRow
            name="Emergency Contact Relationship:"
            value={val.emergecnyContactRelationship}
          />
          <ViewOnlyRow
            name="Emergency Contact Phone:"
            value={val.emergecnyContactPhone}
            white
          />
          <ViewOnlyRow
            name="Other Patient Notes:"
            value={val.otherPatientNotes}
          />
          <ViewOnlyRow name="Medication" list={val.currentMedication} white />
          <ViewOnlyRow name="Medical Conditions" list={val.medicalConditions} />
          <ViewOnlyRow name="Study List" list={val.studies} white study />
        </dl>
      </div>
    </div>
  );
};
