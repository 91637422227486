import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  /*userId: null,*/
  preloadedData: null,
  login: () => {},
  logout: () => {},
  isLoading: false,
  medicationList: [],
  conditionsList: [],
  setIsLoading: () => {},
  setAlert: () => {},
});
