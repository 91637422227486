import React from "react";
import { US_StateNames } from "../../../assets/Utils_DataTemplate";

import SingleInput from "../SingleInput";
import DropDownCustom from "../DropDownCustom";

export default ({ patientInfo, setPatientInfo, validateForm }) => {
  return (
    <div>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              <strong> Contact Information</strong>
            </h3>
          </div>

          <hr />
          <div className="grid grid-cols-6 gap-6">
            <SingleInput
              label="Email"
              name="email"
              placeholder="email@example.com"
              valid={validateForm.email}
              errMsg="You need to enter a valid email in the format example@domain.com"
              value={patientInfo.email}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-6"
              classNameInput=" mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              maxLength={60}
            />

            <SingleInput
              label="Street Address"
              name="address1"
              placeholder="0000 NW 10TH Ave Apt X"
              valid={validateForm.address1}
              errMsg="You need to enter a valid email in the format example@domain.com"
              value={patientInfo.address1}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <SingleInput
              label="City"
              name="city"
              placeholder="Miami"
              valid={validateForm.city}
              errMsg="Enter a valid city"
              value={patientInfo.city}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-6 lg:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              maxLength={20}
            />

            <DropDownCustom
              label="State / Province"
              name="state"
              placeholder="Select"
              valid={validateForm.state}
              errMsg="Select a State"
              value={patientInfo.state}
              onChangeValue={setPatientInfo}
              arrVal={US_StateNames}
              classNameDiv="col-span-6 sm:col-span-3 lg:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <SingleInput
              label="ZIP / Postal code"
              name="zip"
              placeholder="00000"
              valid={validateForm.zip}
              errMsg="Enter a valid ZIP code"
              value={patientInfo.zip}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-6 lg:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              maxLength={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
