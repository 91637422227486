import React, { useEffect, useState } from "react";
import axios from "axios";

const GenderTemp = () => {
  const [patients, setPatients] = useState("");
  const [reload, setReload] = useState(false);

  useState(() => {
    async function getData() {
      try {
        const res = await axios.get("/patients/gendertemp");
        setPatients(res.data);
        console.log(res.data);
      } catch (e) {
        alert("ERROR WHEN GETTING PATIENTS GENDER");
      }
    }

    getData();
  }, [reload]);

  if (reload === true) {
    //window.location.reload();
    setReload(false);
  }

  if (!patients) return <h1>Loading</h1>;

  return (
    <div className="w-full">
      <h1 className="mx-2" style={{ fontSize: 30 }}>
        Gender Temp Count: {patients.length}
      </h1>
      {patients.map((item) => (
        <div key={item.id} className="flex flex-row w-full my-12">
          <input
            id={item.id}
            value={item.id}
            disabled
            className="block  px-5 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2"
          />

          <input
            id={item.id + item.name}
            value={item.name}
            disabled
            className="block px-5 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2"
          />

          <CustomGenderSelect
            value={item.gender}
            pid={item.id}
            setReload={setReload}
            reload={reload}
          />
        </div>
      ))}
    </div>
  );
};

function CustomGenderSelect({ value, pid, setReload, reload }) {
  const [current, setCurrent] = useState(value);

  async function onChange(e) {
    setCurrent(e.target.value);
    console.log(e.target.value);
    console.log(pid);

    try {
      await axios.patch("/patients/gendertemp/" + pid, {
        gender: e.target.value,
      });
    } catch (e) {
      alert("ERROR WHEN GETTING PATIENTS GENDER");
    }

    setReload(true);
  }

  return (
    <select
      id="country"
      name="country"
      autoComplete="country-name"
      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
      onChange={onChange}
      value={current}
    >
      <option value="N/A">N/A</option>
      <option value="Female">Female</option>
      <option value="Male">Male</option>
    </select>
  );
}

export default GenderTemp;
