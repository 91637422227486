import { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  UserGroupIcon,
  BeakerIcon,
  DocumentDuplicateIcon,
  DocumentChartBarIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import logoH from "../../assets/img/logoH.png";
import { AuthContext } from "../../auth/useAuthContext";

const navigation = [
  {
    name: "Patient List",
    href: "/patients",
    icon: UserGroupIcon,
    current: false,
    tab: 1,
  },
  {
    name: "Patient Studies",
    href: "/patient-studies",
    icon: DocumentDuplicateIcon,
    current: false,
    tab: 2,
  },

  {
    name: "Studies",
    href: "/studies",
    icon: BeakerIcon,
    current: false,
    tab: 3,
  },

  {
    name: "Illness and Medication",
    href: "/medication-and-conditions",
    icon: VaccinesIcon,
    current: false,
    tab: 4,
  },

  {
    name: "Reports",
    href: "/reports",
    icon: DocumentChartBarIcon,
    current: false,
    tab: 5,
  },
  {
    name: "Logout",
    href: "/",
    icon: ArrowLeftOnRectangleIcon,
    current: false,
    tab: 6,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default ({ children }) => {
  const { logout } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const tabRef = useRef(1);
  useEffect(() => {
    setSelectedTab(parseInt(tabRef.current));
  }, []);

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800 ">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              {/*TODO: LOGO GOES HERE*/}
              <div className="flex flex-shrink-0 items-center px-4 mb-5">
                <img className="h-10 w-auto" src={logoH} alt="Your Company" />
              </div>

              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map((item, index) => {
                  selectedTab === item.tab
                    ? (item.current = true)
                    : (item.current = false);
                  return (
                    <Link to={item.href} key={index}>
                      <button
                        value={item.tab}
                        onClick={(e) => {
                          const parsedVal = parseInt(e.target.value);
                          setSelectedTab(parsedVal);
                          tabRef.current = parsedVal;
                          if (item.tab === 6) setTimeout(() => logout(), 5);
                        }}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    </Link>
                  );
                })}
              </nav>
              <div
                className="text-gray-300 mx-2 pl-3 text-center pb-5 pt-2"
                style={{ border: "5px dashed red" }}
              >
                <h1 className="text-red-400 font-bold text-xl pr-4 mb-5">
                  COMPLETED TASKS
                  <hr />
                </h1>
                <ol>
                  <li className="mb-2">
                    - Last Updated on: 06/06/2023 @ 1:56PM{" "}
                  </li>
                  <li> Changed name of reports for Myrak Research to Golden Research</li>
                  {/*<li>- Remove PhoneRequired constraint</li>
                  <li>
                    - Moved Phone field from Contact Section to Profile Section
                  </li>
                  <li>- Hide the Additional comments from patient profile</li>
                  <li>
                    - Removed requirements constraints for Studies for fields:
                    [symptoms_date, screening_date, study_result,isVaccinated]
                  </li>
                  <li>- Removed Delete Button from PatientStudies Section</li>
                  <li className="mb-2">
                    - Removed the special route for correcting N/A genders at
                    /gendertemp
                  </li>
                  <li className="mb-2">
                    - Added the scanning feature for FL IDS{" "}
                  </li>*/}
                  {/* <li className="mb-2">
                    - Fix infinite loading after patient editing{" "}
                  </li>*/}
                  {/*<li className="mb-2">- Fixed Index of Reports</li>
                  <li className="mb-2">
                    - Fixed Medical Condition and Medication check mark
                  </li>
                  <li className="mb-2">
                    - Fixed Medical Condition and Medication changed fields
                  </li>
                  <li className="mb-2">
                    - Added Automated Phone Number Dashes xxx-xxx-xxxx
                  </li>
                  <li className="mb-2">- Allowed spaces in the Study Name</li>*/}
                  <li className="mb-2 text-red-400 font-bold text-xl ">
                    Version No. 2.7
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" md:pl-64 h-full bg-gray-200  pt-7 flex justify-center"
          style={{ minHeight: "100vh" }}
        >
          {children}
        </div>
      </div>
    </>
  );
};
