import React, { useState, useEffect } from "react";

import {
  CreditCardIcon,
  KeyIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import { patientTemplateValidator } from "../../../assets/Utils_DataTemplate";
import ScanPatientProfile from "../BarcodeScan/ScanPatientProfile";
import PatientContactInformation from "./PatientContactInformation";
import PatientEmergencyContact from "./PatientEmergencyContact";
import PatientMedicalConditions from "./PatientMedicalConditions";
import PatientProfile from "./PatientProfile";
import PatientStudies from "./PatientStudies";
import PatientFormValidator from "../../../hooks/PatientFormValidator";

const navigation = [
  { tab: 1, name: "Profile", href: "#", icon: UserCircleIcon, current: true },
  {
    tab: 2,
    name: "Contact Information",
    href: "#",
    icon: KeyIcon,
    current: false,
  },
  {
    tab: 3,
    name: "Health Information",
    href: "#",
    icon: UserGroupIcon,
    current: false,
  },
  {
    tab: 4,
    name: "Emergency Contact",
    href: "#",
    icon: CreditCardIcon,
    current: false,
  },

  {
    tab: 5,
    name: "Patient Studies",
    href: "#",
    icon: SquaresPlusIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default ({
  addPerson,
  patientProfile,
  setPatientProfile,
  isLoading,
  setIsLoading,
  resetPatientProfile,
}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [validateForm, setValidateForm] = PatientFormValidator(
    patientTemplateValidator
  );

  const onChangeInput = (e) => {
    setPatientProfile(e);
    setValidateForm(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addPerson();
  };

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 mt-10 mb-5 mx-5">
      <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
        <nav className="space-y-1">
          {navigation.map((item, index) => {
            selectedTab === item.tab
              ? (item.current = true)
              : (item.current = false);
            return (
              <button
                key={index}
                value={item.tab}
                onClick={(e) => {
                  e.preventDefault();
                  const parsedVal = parseInt(e.target.value);
                  setSelectedTab(parsedVal);
                }}
                className={classNames(
                  item.current
                    ? "bg-gray-300 text-indigo-700 hover:text-indigo-700 hover:bg-gray-100"
                    : "text-gray-900 hover:text-gray-900 hover:bg-gray-300",
                  "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-indigo-500 group-hover:text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </button>
            );
          })}
        </nav>
      </aside>

      <form onSubmit={handleSubmit} className="sm:px-6 lg:col-span-9 lg:px-0">
        {selectedTab === 1 && (
          <div>
            <PatientProfile
              patientInfo={patientProfile}
              setPatientInfo={onChangeInput}
              validateForm={validateForm}
              resetPatientProfile={resetPatientProfile}
            />
          </div>
        )}

        {selectedTab === 2 && (
          <PatientContactInformation
            patientInfo={patientProfile}
            setPatientInfo={onChangeInput}
            validateForm={validateForm}
          />
        )}
        {selectedTab === 3 && (
          <PatientMedicalConditions
            patientInfo={patientProfile}
            setPatientInfo={onChangeInput}
            validateForm={validateForm}
          />
        )}

        {selectedTab === 4 && (
          <PatientEmergencyContact
            patientInfo={patientProfile}
            setPatientInfo={onChangeInput}
            validateForm={validateForm}
          />
        )}

        {selectedTab === 5 && (
          <PatientStudies
            patientInfo={patientProfile}
            setPatientInfo={onChangeInput}
            validateForm={validateForm}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
        <div className="shadow bg-white px-4 py-3 text-right sm:px-6 rounded mt-2">
          <button
            /* onClick={handleSubmit}*/
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
