import React from "react";
import { relationshipList } from "../../../assets/Utils_DataTemplate";
import SingleInput from "../SingleInput";
import DropDownCustom from "../DropDownCustom";

export default ({ patientInfo, setPatientInfo, validateForm }) => {
  return (
    <div>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              <strong>Emergency Contact Information</strong>
            </h3>
          </div>

          <hr />
          <div className="grid grid-cols-6 gap-6">
            <SingleInput
              label="First and Last Name"
              name="emergecnyContactName"
              placeholder="First and Middle Name"
              valid={validateForm.emergecnyContactName}
              errMsg="You need to enter a valid  Name"
              value={patientInfo.emergecnyContactName}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-6"
              maxLength={30}
            />{" "}
            <DropDownCustom
              label="Relationship to Patient"
              name="emergecnyContactRelationship"
              placeholder="Select"
              valid={validateForm.emergecnyContactRelationship}
              errMsg="Select a State"
              value={patientInfo.emergecnyContactRelationship}
              onChangeValue={setPatientInfo}
              arrVal={relationshipList}
              classNameDiv="col-span-6 sm:col-span-6"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <SingleInput
              label="Emergency Phone Number"
              name="emergecnyContactPhone"
              placeholder="xxx-xxx-xxxx"
              valid={validateForm.emergecnyContactPhone}
              errMsg="You need to enter a phone in the format xxx-xxx-xxxx"
              value={patientInfo.emergecnyContactPhone}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-6"
              classNameInput=" mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              maxLength={12}
            />
          </div>

          <div className="grid grid-cols-6 gap-6" />
        </div>
      </div>
    </div>
  );
};
