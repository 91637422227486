import React from "react";
import dayjs from "dayjs";

export default ({ age, classNameInput, classNameDiv }) => {
  const defClassName = classNameInput
    ? classNameInput
    : "mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm";

  //TODO: Handle Cases fro age less than 1 or 1
  return (
    <div className={classNameDiv ? classNameDiv : "col-span-6 sm:col-span-2}"}>
      <label
        htmlFor="RandomAgeDisplayName"
        className="block text-sm font-medium text-gray-700"
      >
        Age of Patient
      </label>
      <input
        type="input"
        name="RandomAgeDisplayName"
        id="RandomAgeDisplay"
        className={defClassName}
        placeholder="AGE"
        disabled
        value={
          age
            ? Math.floor(dayjs(new Date()).diff(age, "year", true)) +
              " Years old"
            : "DOB Not Provided"
        }
      />
    </div>
  );
};
