import React from "react";

export default ({ list }) => {
  const tempList = list.map((item, index) => (
    <div
      key={index}
      className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 p-1 mr-2 mt-1 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
    >
      {item}
    </div>
  ));
  return <div className="flex flex-wrap ">{tempList}</div>;
};
