import React from "react";
import ListLinks from "./ListLinks";
import ListLinksSt from "./ListLinksSt";

export default ({ name, value, white, list, study }) => {
  let className = "text-gray-600";
  if (value === "Positive") className = "text-red-600";
  else if (value === "Negative") className = "text-green-600";
  return (
    <div
      className={`${
        white ? "bg-white" : "bg-gray-50"
      } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
    >
      <dt className="text-gray-500">{name}</dt>

      <dd className={"mt-1 text-sm sm:col-span-2 sm:mt-0 " + className}>
        {list ? (
          study ? (
            <ListLinksSt list={list} />
          ) : (
            <ListLinks list={list} />
          )
        ) : (
          value
        )}
      </dd>
    </div>
  );
};
