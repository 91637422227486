import React, { useState, useContext } from "react";
import LOGO from "../../assets/img/logoVBlue.png";
import axios from "axios";
import { credentialsTemplate } from "../../assets/Utils_DataTemplate";
import ObjHook from "../../hooks/ObjHook";
import { AuthContext } from "../../auth/useAuthContext";
import Loader from "../LoaderOverlay/Loader";

export default () => {
  const { isLoading, setIsLoading, setAlert, login } = useContext(AuthContext);
  const [credentials, setCredentials, resetCredentials] =
    ObjHook(credentialsTemplate);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post("/login", credentials);
      if (res.status === 201) {
        login(res.data.token, res.data.preloaded);
        window.location.reload(false);
      } else setAlert("0030 Credentials provided are invalid ", false);
    } catch (err) {
      setAlert(
        "0027 Unable to sign in because of the following reason: ",
        false,
        err
      );
    }
    resetCredentials(credentialsTemplate);
    setTimeout(() => setIsLoading(false), 3000);
  };

  if (isLoading) return <Loader active />;

  return (
    <>
      <div
        className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50"
        style={{ height: "100vh" }}
      >
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-12 shadow sm:rounded-lg sm:px-12 py-12">
            <form
              className="space-y-6 "
              action="#"
              method="POST"
              onSubmit={onLogin}
            >
              <div className="sm:mx-auto sm:w-full sm:max-w-md mb-12">
                <img
                  className="mx-auto h-12 w-auto"
                  src={LOGO}
                  alt="Your Company"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={setCredentials}
                    value={credentials.email}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={setCredentials}
                    value={credentials.password}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-24"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
