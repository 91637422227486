import React from "react";

export default ({
  person,
  setSelectedPeople,
  selectedPeople,
  onPatientEdit,
  classNames,
  onPatientView,
  onAddStudy,
}) => {
  return (
    <tr
      className={
        customInclude(selectedPeople, person) ? "bg-gray-50" : undefined
      }
    >
      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
        {customInclude(selectedPeople, person) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          value={person.email}
          checked={customInclude(selectedPeople, person)}
          onChange={() =>
            setSelectedPeople(
              customInclude(selectedPeople, person)
                ? selectedPeople.filter((p) => p.id !== person.id)
                : [...selectedPeople, person]
            )
          }
        />
      </td>
      <td
        className={classNames(
          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
          selectedPeople.includes(person) ? "text-indigo-600" : "text-gray-900"
        )}
      >
        {person.firstName + " " + person.lastName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.idType}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.valid_id}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.dob}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.phone}
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          onClick={onPatientEdit}
          className="text-indigo-600 hover:text-indigo-900 ml-2"
          value={person.id}
        >
          Edit
          <span className="sr-only">, {person.name}</span>
        </button>
      </td>

      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          onClick={onPatientView}
          className="text-indigo-600 hover:text-indigo-900 mr-2"
          value={person.id}
        >
          View
          <span className="sr-only">, {person.name}</span>
        </button>
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          onClick={onAddStudy}
          className="text-indigo-600 hover:text-indigo-900 mr-2"
          value={person.id}
        >
          Add Study
          <span className="sr-only">, {person.name}</span>
        </button>
      </td>
    </tr>
  );
};

function customInclude(arr, obj) {
  const a = arr.filter((p) => p.id === obj.id);
  return a.length > 0;
}
