import React from "react";

export default ({
  person,
  setPeople,
  people,
  index,
  selectedPeople,
  setSelectedPeople,
}) => {
  return (
    <tr
      className={
        customInclude(selectedPeople, person) ? "bg-gray-50" : undefined
      }
    >
      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
        {customInclude(selectedPeople, person) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          value={person.name_code}
          checked={person.visible}
          onChange={(e) => {
            let temp = [...people];
            if (e.target.checked) temp[index].visible = true;
            else temp[index].visible = false;
            setPeople(temp);

            setSelectedPeople(
              customInclude(selectedPeople, person)
                ? selectedPeople.filter((p) => p.id !== person.id)
                : [...selectedPeople, person]
            );
          }}
        />
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.value}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {person.type}
      </td>
    </tr>
  );
};

function customInclude(arr, obj) {
  const a = arr.filter((p) => p.id === obj.id);
  return a.length > 0;
}
