import React, { useEffect, useState } from "react";

export default ({ customList, setPatientData, dbData, field }) => {

  const [options, setOptions] = useState(customList);

  const handleOnChange = (e) => {
    const item = e.target.name;


    //Has to Shadow copy to avoid waiting for re-rendering
    let tempOptions = [];

    if (e.target.checked) tempOptions = [...options, item];
    else tempOptions = options.filter((p) => p !== item);

    setOptions(tempOptions);

    let updatedValue = {};
    if (field === "currentMedication") {
      updatedValue = { currentMedication: tempOptions };
    } else if (field === "medicalConditions") {
      updatedValue = { medicalConditions: tempOptions };
    }

    e.target.customx = true;
    e.target.customx_data = updatedValue;
    setPatientData(e);
  };

  return (
    <fieldset className="space-y-1  w-full overflow-y-scroll max-h-52">
      {dbData.map((item, index) => (
          <CheckBox
              key={item.id}//index
              id={item.id}//index
              name={item.value}
              setChecked={handleOnChange}
              checked={options.includes(item.value)}
          />
      ))}
    </fieldset>
  );
};

const CheckBox = ({ name, checked, setChecked }) => {
  return (
    <div className="relative flex items-start">
      <div className="h-5 flex items-center">
        <input
          id={name}
          aria-describedby="comments-description"
          name={name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          checked={checked}
          onChange={setChecked}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700">
          {name}
        </label>
      </div>
    </div>
  );
};

function customInclude(arr, obj) {
  const a = arr.filter((p) => p.id === obj.id);
  return a.length > 0;
}

