const isEmpty = (string) => {
  return string.trim() === "";
};

const isNotEmpty = (string) => {
  return !isEmpty(string);
};

const isValidEmail = (email) => {
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return email.match(regEx) && isNotEmptyAndLessThan75(email);
};

const isValidPassword = (password) => {
  const regEx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
  //const regEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

  return password.match(regEx);
};

const isValidRequestType = (request) => {
  if (
    request === "New User Request" ||
    request === "Request Additional Access" ||
    request === "Termination Request"
  )
    return true;
  return false;
};

const isNotEmptyAndLessThan100 = (field) => {
  if (isEmpty(field)) return false;
  else if (field.length > 100) return false;

  return true;
};

const greaterThan2AndLessThan30 = (field) => {
  if (field.length < 2) return false;
  else if (field.length > 30) return false;

  return true;
};

const isBetweenInclussive = (field, lower, upper) => {
  if (field.length <= lower) return false;
  else if (field.length >= upper) return false;

  return true;
};

const isBetweenExclussive = (field, lower, upper) => {
  if (field.length < lower) return false;
  else if (field.length > upper) return false;

  return true;
};

const isNotEmptyAndLessThan75 = (field) => {
  if (isEmpty(field)) return false;
  else if (field.length > 75) return false;

  return true;
};

const isValidSimpleWordExtended = (field) => {
  const regex = /^([a-zA-Z ]+[\-\'])|[a-zA-Z ]$/;
  return field.match(regex);
};

const isValidName = (field) => {
  return isValidSimpleWordExtended(field) && isBetweenExclussive(field, 1, 30);
};

const isValidID = (field) => {
  const regex = /^[a-zA-Z0-9-]*$/;
  return regex.test(field) && isBetweenExclussive(field, 5, 30);
};

const isValidSSN = (field) => {
  const regex = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
  return regex.test(field);
};

const isNotGreaterThan300 = (field) => {
  if (field.length > 300) return false;
  return true;
};

const isValidZIP = (field) => {
  const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return regex.test(field);
};

const isValidAddress = (field) => {
  const regex = /^[#.0-9a-zA-Z\s,-]+$/;
  return regex.test(field);
};

const isValidUSPhoneNumber = (field) => {
  const regex = /^[2-9]\d{2}-\d{3}-\d{4}$/;
  return regex.test(field);
};

const isGreaterThan5 = (field) => {
  return field.length > 5;
};

const isLessThan500 = (field) => {
  return field.length < 500;
};

const isPositiveInteger = (field) => {
  const regex = /^[0-9]*$/;
  return regex.test(field);
};

const isAlphanumeric = (field) => {
  const regex = /^[A-Za-z0-9]*$/;
  return regex.test(field);
};

const isAlphanumericPlusSpace = (field) => {
  const regex = /^[A-Za-z0-9 ]*$/;
  return regex.test(field);
};

const isValidIllnessOrMedication = (field) => {
  const regex = /^[a-zA-Z0-9-.']*$/;
  return regex.test(field);
};

export {
  isLessThan500,
  isValidName,
  isValidZIP,
  isValidEmail,
  isValidID,
  isValidSSN,
  isValidAddress,
  isValidUSPhoneNumber,
  isGreaterThan5,
  isPositiveInteger,
  isEmpty,
  isAlphanumeric,
  isValidIllnessOrMedication,
  isAlphanumericPlusSpace,
};
