import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

export default ({
  label,
  valid,
  errMsg,
  value,
  onChangeValue,
  name,
  classNameDiv,
  classNameInput,
  arrVal,
  objVal,
  disabled,
}) => {
  const idSalt = "-xhxqo";

  let valuesSelect = "";

  if (!objVal)
    valuesSelect = arrVal.map((item, index) => (
      /*index == 0 ? (
        <option key={index} value="Select a Value">
          Select a Value
        </option>
      ) : */ <option key={index} value={item}>
        {item}
      </option>
    ));

  switch (objVal) {
    case "name": {
      valuesSelect = arrVal.map((item, index) => (
        <option key={index} value={item.name}>
          {item.name}
        </option>
      ));
      break;
    }
  }

  const errClassName =
    "focus:ring-red-500 text-red-900 placeholder-red-300 focus:border-red-500 border-red-300";
  const defClassName = classNameInput
    ? classNameInput
    : "mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm";

  return (
    <div className={classNameDiv ? classNameDiv : "col-span-6 sm:col-span-3"}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id={label + idSalt}
        name={name}
        autoComplete="country-name"
        className={
          !valid && errMsg ? errClassName + " " + defClassName : defClassName
        }
        onChange={onChangeValue}
        value={value}
        disabled={disabled ? disabled : false}
      >
        {valuesSelect}
      </select>
      {!valid && errMsg && (
        <div
          className="flex flex-row justify-center items-center mt-1"
          aria-hidden="true"
        >
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
          <p className=" text-sm text-red-600" id={label + "-error" + idSalt}>
            {errMsg}
          </p>
        </div>
      )}
    </div>
  );
};
