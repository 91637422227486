import React, { useState } from "react";

export default (initialState) => {
  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    let updatedValue = {};
    if (e.target.customx) {
      updatedValue = e.target.customx_data;
    } else if (e.target.name === "phone") {
      if (
        e.target.value.toString().length === 3 ||
        e.target.value.toString().length === 7
      )
        updatedValue = { [e.target.name]: e.target.value + "-" };
      else updatedValue = { [e.target.name]: e.target.value };
    } else {
      updatedValue = { [e.target.name]: e.target.value };
    }
    setState((state) => ({
      ...state,
      ...updatedValue,
    }));
  };

  const removeState = (e) => {
    let copyOfObject = { ...state };
    delete copyOfObject[e.target.value];

    setState((copyOfObject) => ({
      ...copyOfObject,
    }));
  };

  const resetState = (newS) => {
    setState(newS);
  };

  return [state, handleChange, resetState, removeState];
};
