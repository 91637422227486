import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../auth/useAuthContext";

const PrivateRoute = ({ children }) => {
  const { token } = useContext(AuthContext);
  //const token = false;
  return token ? children : <Navigate to="/login" />;
};

const PrivateRouteLoggedIn = () => {
  return <Navigate to="/" />;
};

export { PrivateRoute, PrivateRouteLoggedIn };
