import React, { useState, useContext } from "react";
import {
  validateIllnessAndMed,
  validatePatientStudyData,
} from "../../../assets/ValidateSubmission";

import { AuthContext } from "../../../auth/useAuthContext";
import Loader from "../../LoaderOverlay/Loader";
import FormWrapper from "../FormWrapper";
import SingleInput from "../SingleInput";
import DropDownCustom from "../DropDownCustom";
import axios from "axios";

export default () => {
  const { isLoading, setIsLoading, setAlert } = useContext(AuthContext);
  const [textField, setTextField] = useState("");
  const [option, setOption] = useState("Medication");

  const submitStudy = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let tempObj = {
      value: textField,
      type: option,
    };

    const err = validateIllnessAndMed(tempObj);
    if (err.length > 0) {
      setAlert(err, true);
      //setIsLoading(false);
      return;
    } else {
      try {
        const res = await axios.post("/medication-and-condition", tempObj);
        //setIsLoading(false);
      } catch (err) {
        setAlert(
          "An Error has Occurred when adding a new medication or condition [0019]",
          false,
          err
        );
        //setIsLoading(false);
      }
    }
    resetFields();
    //submit();
    //setIsLoading(false);
  };

  const resetFields = () => {
    setTextField("");
    setOption("Medication");
  };

  //isLoading && <Loader active={isLoading} />;

  return (
    <form
      onSubmit={submitStudy}
      method="POST"
      className="lg:grid lg:grid-cols-12 lg:gap-x-5 mt-10 mb-5"
    >
      <div className="lg:col-span-12 w-full ">
        {/* */}

        <FormWrapper title="Create a Medication or Condition">
          <SingleInput
            label="Name of Condition or Medication"
            name="Condition_or_Medication"
            placeholder="Condition or Medication"
            value={textField}
            onChangeValue={(e) => setTextField(e.target.value)}
            classNameDiv="col-span-6 sm:col-span-4"
            maxLength={30}
          />
          <DropDownCustom
            label="Type?"
            name="Type"
            value={option}
            onChangeValue={(e) => setOption(e.target.value)}
            arrVal={["Medication", "Condition"]}
            classNameDiv="col-span-6 sm:col-span-2"
            classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </FormWrapper>

        <div className="shadow bg-white px-4 py-3 text-right sm:px-6 rounded mt-2">
          <button
            type="submit"
            onClick={submitStudy}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
