import React from "react";

export default ({ onClick, title }) => {
  return (
    <div className="inline-flex  justify-center rounded-md shadow w-full my-2 ">
      <button
        onClick={onClick}
        className="inline-flex w-full items-center justify-center align-stretch rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
      >
        {title}
      </button>
    </div>
  );
};
