const patientTemplate = {
  valid_id: "",
  idType: "",
  firstName: "",
  lastName: "",
  ssn: "",
  dob: "",
  address1: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  ethnicity: "",
  race: "",
  gender: "",
  childBearingPotential: "",
  reasonOfNo: "",
  emergecnyContactName: "",
  emergecnyContactRelationship: "",
  emergecnyContactPhone: "",
  otherPatientNotes: "",
  currentMedication: [],
  medicalConditions: [],
};

const studies = {
    study_id: "",
    study_name: "",
    screening_date: "",
    symptoms_date: "",
    study_result: "",
    isVaccinated: "",
    vaccineType: "",
    lastVaccine: "",
    vaccineDosesTaken: "",
    study_required_visits: "",
    otherSymptoms: "",
  },
  patientTemplateValidator = {
    valid_id: false,
    idType: false,
    firstName: false,
    lastName: false,
    ssn: false,
    dob: false,
    age: false,
    address1: false,
    city: false,
    state: false,
    zip: false,
    phone: false,
    email: false,
    ethnicity: false,
    race: false,
    gender: false,
    childBearingPotential: false,
    reasonOfNo: false,
    emergecnyContactName: false,
    emergecnyContactRelationship: false,
    emergecnyContactPhone: false,
    otherPatientNotes: false,
  };

const patientStudyTemplate = {
  study_id: "",
  study_name: "",
  screening_date: "",
  symptoms_date: "",
  study_result: "",
  isVaccinated: "",
  vaccineType: "",
  lastVaccine: "",
  vaccineDosesTaken: "",
  study_required_visits: "",
  otherSymptoms: "",
  visitNumber: 1,
};

const patientStudyValidator = {
  study_id: false,
  study_name: false,
  screening_date: false,
  symptoms_date: false,
  study_result: false,
  isVaccinated: false,
  vaccineType: false,
  lastVaccine: false,
  vaccineDosesTaken: false,
  visitNumber: false,
  otherSymptoms: false,
};

const studyTemplate = {
  name: "",
  visible: true,
  name_code: "",
};

const studyTemplateValidator = {
  name: false,
  required_visits: false,
  name_code: false,
};

const US_StateNames = [
  "Select",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const idTypes = [
  "Select",
  "Drivers-License",
  "US-Passport",
  "Green-Card",
  "Student-ID",
  "Military-ID",
  "SSN",
  "Foreign-Passport",
  "Other-ID",
];

const trueFalse = ["Select", "Yes", "No"];

const genderList = ["Select", "Male", "Female", "Other"];

const ethnicList = [
  "Select",
  "Hispanic/Latino",
  "Not Hispanic/Latino",
  "Unknown/Prefer not to answer",
];

const racesList = [
  "Select",
  "American Indian or Alaskan Native",
  "Asian",
  "Black/African American",
  "Native Hawaiian or Pacific Islander",
  "White",
  "Unknown/Prefer not to answer",
  "Other",
];

const relationshipList = [
  "Select",
  "Self",
  "Parent",
  "Spouse",
  "Sibling",
  "Cousin",
  "Friend",
  "Work Related",
  "School Related",
  "Religion Related",
  "Medical Related",
  "Other",
];

const listOfStudies = [
  { name: "Select", id: "SEL", visits: 0 },
  { name: "COVID", id: "COV", visits: 5 },
  { name: "FLU", id: "FLU", visits: 3 },
  { name: "CANCER", id: "CAN", visits: 3 },
  { name: "HPV", id: "HPV", visits: 2 },
  { name: "HSV1", id: "HS1", visits: 2 },
  { name: "HSV2", id: "HS2", visits: 5 },
  { name: "HIV", id: "HIV", visits: 4 },
  { name: "POX", id: "POX", visits: 4 },
];

const studyResult = ["Select", "Positive", "Negative", "Invalid"];

const vaccineType = ["Select", "Pfizer", "Moderna", "Johnson&Johnson"];

const numericArrVal = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

const credentialsTemplate = { email: "", password: "" };

const filterReportTemplate = {
  fromAge: "",
  toAge: "",
  study_name: "",
  studyResult: "",
  med_condition: "",
  medication: "",
};

export {
  numericArrVal,
  studyTemplate,
  studyTemplateValidator,
  studyResult,
  vaccineType,
  patientStudyTemplate,
  listOfStudies,
  relationshipList,
  ethnicList,
  racesList,
  trueFalse,
  genderList,
  patientTemplate,
  patientTemplateValidator,
  US_StateNames,
  idTypes,
  filterReportTemplate,
  credentialsTemplate,
};
