import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import IndexPatientForm from "../components/Forms/PatientProfileForm/IndexPatientForm";
import IndexTable from "../components/Tables/PatientListTable/IndexPatientListTable";
import IndexStudiesListTable from "../components/Tables/StudiesListTable/IndexStudiesListTable";
import IndexPatientStudiesListTable from "../components/Tables/PatientStudiesListTable/IndexPatientStudiesListTable";
import IndexReports from "../components/Reports/IndexReports";
import IndexMedicationAndConditions from "../components/Tables/MedicationAndConditions/IndexMedicationAndConditions";
import Login from "../components/Login/Login";
import { PrivateRoute, PrivateRouteLoggedIn } from "./PrivateRoute";
import GenderTemp from "../components/GenderTemp/GenderTemp";
import ScanPatientProfile from "../components/Forms/BarcodeScan/ScanPatientProfile";
import { AuthContext } from "../auth/useAuthContext";

export default () => {
  const { isLoggedIn } = useContext(AuthContext);
  //const isLoggedIn = false;

  const routes = (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <PrivateRoute>
            <IndexTable />
          </PrivateRoute>
        }
      />
      {/* <Route
        exact
        path="/gendertemp"
        element={
          <PrivateRoute>
            <GenderTemp />
          </PrivateRoute>
        }
      />*/}
      {/* <Route exact path="/scanprofile" element={<ScanPatientProfile />} />*/}
      <Route
        exact
        path="/patients"
        element={
          <PrivateRoute>
            <IndexTable />{" "}
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/add-patient"
        element={
          <PrivateRoute>
            <IndexPatientForm />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/studies"
        element={
          <PrivateRoute>
            <IndexStudiesListTable />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/reports"
        element={
          <PrivateRoute>
            <IndexReports />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/medication-and-conditions"
        element={
          <PrivateRoute>
            <IndexMedicationAndConditions />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/patient-studies"
        element={
          <PrivateRoute>
            <IndexPatientStudiesListTable />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="*"
        element={
          <PrivateRoute>
            <IndexTable />
          </PrivateRoute>
        }
      />
      {!isLoggedIn && <Route exact path="/login" element={<Login />} />}
      {isLoggedIn && (
        <Route exact path="/login" element={<PrivateRouteLoggedIn />} />
      )}
      <Route exact path="*" element={<PrivateRouteLoggedIn />} />}
    </Routes>
  );

  return <React.Fragment>{routes} </React.Fragment>;
};
