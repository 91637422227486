import {
  isEmpty,
  isValidName,
  isValidUSPhoneNumber,
  isValidID,
  isPositiveInteger,
  isAlphanumeric,
  isAlphanumericPlusSpace,
} from "./Validator";
import dayjs from "dayjs";

function validatePatientData(P) {
  let errors = [];
  if (isEmpty(P.dob)) errors.push("Need to select a DOB");
  if (!isValidName(P.firstName)) errors.push("Need enter a valid first name");
  if (!isValidName(P.lastName)) errors.push("Need enter a valid last name");
  //TODO: Disabled by requester
  /*if (!isValidUSPhoneNumber(P.phone))
    errors.push("Need enter a valid phone number in the format xxx-xxx-xxxx");*/
  if (!isValidID(P.valid_id)) errors.push("Need enter a valid ID");
  if (isEmpty(P.idType)) errors.push("The ID Type cannot be empty");
  if (P.dob) {
    const dobDiff = dayjs(new Date()).diff(P.dob, "year", true);

    if (dobDiff < 0)
      errors.push(
        "The Date of Birth cannot be in the future. Please select a date prior to today's date"
      );
    if (dobDiff > 100)
      errors.push(
        "The Date of Birth cannot be older than 100 years from today's date. Please select a date within 100 years from today's date"
      );
  }

  if (!P.gender || (P.gender !== "Female" && P.gender !== "Male"))
    errors.push("A gender must be selected");

  /*  if (P.gender !== "Female") {
    P.childBearingPotential = "No";
    P.reasonOfNo = "N/A";
  }
  if (
    !P.gender ||
      P.gender === "Select" || P.gender === "N/A"
  )
    P.gender = "N/A";*/

  return errors;
}

/*

      isVaccinated: false,
      vaccineType: false,
      lastVaccine: false,
      vaccineDosesTaken: false,
      visitNumber: false,
      otherSymptoms: false,*/

//TODO: Validate Grater Dates
//TODO: Validate Vaccinated

function validatePatientStudyData(P) {
  let errors = [];
  if (isEmpty(P.study_id_digits)) errors.push("The Study ID cannot be empty");
  if (!isPositiveInteger(P.study_id_digits))
    errors.push(
      "You Need to enter a valid Study ID, sequence of numbers only."
    );
  /* TODO: Disabled by requester:
      if (isEmpty(P.screening_date)) errors.push("Screening Date Cannot be Empty");
  if (isEmpty(P.symptoms_date)) errors.push("Date of Symptoms Cannot be Empty");
  if (isEmpty(P.study_result)) errors.push("You need to select a study result");
  if (!P.isVaccinated || P.isVaccinated === "No") {
    P.isVaccinated = "No";
    P.lastVaccine = "N/A";
    P.vaccineDosesTaken = "N/A";
    P.vaccineType = "N/A";
  }*/
  if (P.screening_date && P.symptoms_date) {
    const scdDiff = dayjs(new Date()).diff(P.screening_date, "year", true);
    const sympDiff = dayjs(new Date()).diff(P.symptoms_date, "year", true);

    if (sympDiff < 0)
      errors.push(
        "The symptoms date cannot be in the future. Please select a date prior to today's date"
      );
    else if (sympDiff > 1)
      errors.push(
        "The symptoms date cannot be older than 1 year from today's date. Please select a date within 1 year from today's date"
      );

    if (scdDiff < 0)
      errors.push(
        "The screening date cannot be in the future. Please select a date prior or equal to today's date"
      );
    else if (scdDiff > 1)
      errors.push(
        "The screening date cannot be older than 1 year from today's date. Please select a date within 1 year from today's date"
      );

    if (scdDiff > sympDiff)
      errors.push(
        "The symptoms date cannot be greater then the screening date."
      );
  }

  return errors;
}

const validateStudy = (P) => {
  let errors = [];
  if (isEmpty(P.name)) errors.push("The Study Name cannot be empty");
  else if (!isAlphanumericPlusSpace(P.name))
    errors.push(
      "The Study Name contain invalid characters. Valid Characters Are [A-Za-z0-9]"
    );

  if (isEmpty(P.name_code)) errors.push("The Study NameCode cannot be empty");
  else if (!isAlphanumeric(P.name_code))
    errors.push(
      "The Study NameCode contain invalid characters. Valid Characters Are [A-Za-z0-9]"
    );
  if (!P.visible) P.visible = false;

  return errors;
};

const validateIllnessAndMed = (P) => {
  let errors = [];
  if (!P.value)
    errors.push("The name of the medication or condition cannot be empty");
  else if (!validateIllnessAndMed(P.value))
    errors.push(
      "The name of the medication or condition contain invalid characters. Allowed characters are [a-zA-Z0-9-.']"
    );

  return errors;
};

export {
  validatePatientData,
  validatePatientStudyData,
  validateStudy,
  validateIllnessAndMed,
};
