import React from "react";
import {
  idTypes,
  genderList,
  ethnicList,
  racesList,
} from "../../../assets/Utils_DataTemplate";
import Datepicker from "../Datepicker";
import AgeOfPatient from "../AgeOfPatient";
import SingleTextarea from "../SingleTextarea";
import SingleInput from "../SingleInput";
import DropDownCustom from "../DropDownCustom";
import ScanPatientProfile from "../BarcodeScan/ScanPatientProfile";

export default ({
  patientInfo,
  setPatientInfo,
  validateForm,
  resetPatientProfile,
}) => {
  if (patientInfo.gender !== "Female") {
    patientInfo.reasonOfNo = "N/A";
    patientInfo.childBearingPotential = "No";
  }
  return (
    <div>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              <strong>Personal Information</strong>
            </h3>
          </div>

          <hr />
          <div className="grid grid-cols-6 gap-6">
            <ScanPatientProfile
              setPatient={resetPatientProfile}
              patient={patientInfo}
            />

            <SingleInput
              label="First Name"
              name="firstName"
              placeholder="First and Middle Name"
              valid={validateForm.firstName}
              errMsg="You need to enter a valid First Name"
              value={patientInfo.firstName}
              onChangeValue={setPatientInfo}
              maxLength={20}
            />

            <SingleInput
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
              valid={validateForm.lastName}
              errMsg="You need to enter a valid Last Name"
              value={patientInfo.lastName}
              onChangeValue={setPatientInfo}
              maxLength={20}
            />

            <SingleInput
              label="ID"
              name="valid_id"
              placeholder="Identification (No Dashes)"
              valid={validateForm.valid_id}
              errMsg="ID is Not Valid"
              value={patientInfo.valid_id}
              onChangeValue={setPatientInfo}
              maxLength={20}
              classNameDiv="col-span-6 sm:col-span-2"
            />

            <DropDownCustom
              label="ID Type"
              name="idType"
              valid={validateForm.idType}
              errMsg="Please Select An ID Type"
              value={patientInfo.idType}
              onChangeValue={setPatientInfo}
              arrVal={idTypes}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <SingleInput
              label="Phone Number"
              name="phone"
              placeholder="xxx-xxx-xxxx"
              valid={validateForm.phone}
              errMsg="You need to enter a phone in the format xxx-xxx-xxxx"
              value={patientInfo.phone}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput=" mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              maxLength={12}
            />

            <Datepicker
              label="Date of Birth"
              name="dob"
              valid={validateForm.dob}
              errMsg="Please Select your a Date of Birth"
              value={patientInfo.dob}
              onChangeValue={setPatientInfo}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <AgeOfPatient
              age={patientInfo.dob}
              classNameDiv="col-span-6 sm:col-span-2"
            />

            <DropDownCustom
              label="Gender"
              name="gender"
              valid={validateForm.gender}
              errMsg="Please Select a Gender"
              value={patientInfo.gender}
              onChangeValue={setPatientInfo}
              arrVal={genderList}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <DropDownCustom
              label="Ethnicity"
              name="ethnicity"
              valid={validateForm.ethnicity}
              errMsg="Please Select an Ethnicity"
              value={patientInfo.ethnicity}
              onChangeValue={setPatientInfo}
              arrVal={ethnicList}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <DropDownCustom
              label="Race"
              name="race"
              valid={validateForm.race}
              errMsg="Please Select a Race"
              value={patientInfo.race}
              onChangeValue={setPatientInfo}
              arrVal={racesList}
              classNameDiv="col-span-6 sm:col-span-2"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            <DropDownCustom
              disabled={patientInfo.gender !== "Female"}
              label="Childbearing Potential"
              name="childBearingPotential"
              value={patientInfo.childBearingPotential}
              onChangeValue={setPatientInfo}
              arrVal={["No", "Yes"]}
              classNameDiv="col-span-6 sm:col-span-1"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
            <DropDownCustom
              disabled={patientInfo.gender !== "Female"}
              label="Reason of No."
              name="reasonOfNo"
              value={patientInfo.reasonOfNo}
              onChangeValue={setPatientInfo}
              arrVal={["N/A", "Hysterectomy", "Post-menopausal"]}
              classNameDiv="col-span-6 sm:col-span-1"
              classNameInput="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />

            {/*TODO: Disabled By Requesdter
            <SingleTextarea
              label="Additional Comments"
              name="otherPatientNotes"
              valid={validateForm.otherPatientNotes}
              errMsg="Please enter your comments in less than 500 characters"
              value={patientInfo.otherPatientNotes}
              onChangeValue={setPatientInfo}
              placeholder="Enter your notes here..."
              maxLength={500}
            />*/}
          </div>
        </div>
      </div>
    </div>
  );
};
