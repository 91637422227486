import {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import SearchInputForm from "../SearchInputForm";
import TableRow from "./TableRow";
import ModalWith2Actions from "../../Modal/ModalWith2Actions";
import ModalPatientViewOnly from "../../Modal/ModalPatientViewOnly";
import "../../style.css";
import React from "react";
import {
  patientStudyTemplate,
  patientTemplate,
} from "../../../assets/Utils_DataTemplate";
import ObjHook from "../../../hooks/ObjHook";
import axios from "axios";
import Loader from "../../LoaderOverlay/Loader";
import { validatePatientData } from "../../../assets/ValidateSubmission";
import HeaderTitle from "../../HeaderTitle";
import ModalPatientStudy from "../../Modal/ModalPatientStudy";
import { AuthContext } from "../../../auth/useAuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default () => {
  const { isLoading, setIsLoading, setAlert } = useContext(AuthContext);
  const pIDRef = useRef(null);

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  //To Setup a new Patient State
  const [people, setPeople] = useState([]);
  const [peopleAPI, setPeopleAPI] = useState([]);

  //For Filtering Values
  const [searchValue, setSearchValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);

  //To Togle Modal for Adding a Patient
  const [showPatientInfoModal, toggleShowPattientInfoModal] = useState(false);
  const [showPatientAddModal, toggleShowPatientAddModal] = useState(false);

  //Toggle Modal View Only Patient
  //To Togle Modal for Adding a Patient
  const [showPatientViewModal, toggleShowPatientViewModal] = useState(false);

  // For Editing
  const [patientEdit, formSetPatientEdit, setPatientEdit] = ObjHook({});

  const [isAddStudy, setIsAddStudy] = useState(false);
  const [studyTemp, setStudyTemp, resetStudyTemp] =
    ObjHook(patientStudyTemplate);

  //useLayoutEffect
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 && selectedPeople.length < people.length;
    setChecked(
      selectedPeople.length === people.length && selectedPeople.length !== 0
    );
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);

  useEffect(() => {
    async function getPatients() {
      try {
        const res = await axios.get("/patients");
        setPeople(res.data);
        setPeopleAPI(res.data);

        if (pIDRef?.current?.value) {
          const element = await axios.get("/patients/" + pIDRef.current.value);
          const data = element.data;
          if (data) setPatientEdit(data);
        }
      } catch (err) {
        setAlert("An Error Has Ocurred [0008]", false, err);
      }
    }
    getPatients();
  }, [isLoading]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  //TODO: Edit on Firebase
  const editPatientSubmit = async () => {
    const err = validatePatientData(patientEdit);
    if (err.length > 0) {
      setAlert(err, true);
      return;
    }

    setIsLoading(true);

    async function updatePatient() {
      try {
        await axios.patch("/patients/" + patientEdit.id, patientEdit);
      } catch (err) {
        setAlert("AN ERROR HAS OCCURRED WHEN SUBMITTING [0003]", false, err);
        setIsLoading(false);
      }
    }
    await updatePatient();
    setIsLoading(false);
    toggleShowPattientInfoModal(!showPatientInfoModal);
  };

  const addPerson = () => {
    setPatientEdit(patientTemplate);
    toggleShowPatientAddModal(!showPatientInfoModal);
  };

  //TODO: Post to Firebase
  const onCreateNewPatient = async () => {
    const filteredArray = people.filter((value) => {
      return (
        value.idType + value.valid_id ===
        patientEdit.idType + patientEdit.valid_id
      );
    });

    const err = validatePatientData(patientEdit);
    if (err.length > 0) {
      setAlert(err, true);
      return;
    }

    async function postPatient() {
      setIsLoading(true);
      try {
        await axios.post("/patients", patientEdit);
      } catch (err) {
        setAlert("AN ERROR HAS OCCURRED WHEN SUBMITTING [0005]", false, err);
      }
      setIsLoading(false);
      toggleShowPatientAddModal(false);
    }

    if (!!filteredArray[0]) {
      setAlert(
        "Cannot add this record because is duplicated...\nThe ID [" +
          patientEdit.idType +
          patientEdit.valid_id +
          "] already exists. [0009]"
      );
    } else {
      setPeople((people) => [...people, patientEdit]);
      /*await */ postPatient();
    }
  };

  const onChangeSearchFilter = (e) => {
    if (e.target.value !== "") {
      setSearchValue(e.target.value);

      const filterTable = people.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setSearchValue(e.target.value);
      setPeople([...peopleAPI]);
    }
  };

  //Table Edit click (not Submit)
  const onPatientEdit = async (e) => {
    await onViewOrEdit(e);
    toggleShowPattientInfoModal(true);
  };

  const onPatientView = async (e) => {
    await onViewOrEdit(e);
    toggleShowPatientViewModal(!showPatientViewModal);
  };

  const onAddStudy = async (e) => {
    await onViewOrEdit(e);
    setIsAddStudy(true);
  };

  const onViewOrEdit = async (e) => {
    pIDRef.current.value = e.target.value;
    setIsLoading(true);
    try {
      const id = e.target.value;

      const element = await axios.get("/patients/" + id);
      const data = element.data;
      //TODO: Add Loader
      if (data) {
        setPatientEdit(data);
      } else setAlert("No Record Found [0010]");
    } catch (e) {
      setAlert("Error [0011]", false, e);
    }

    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await axios.delete("/patients", { data: selectedPeople });
    } catch (err) {
      setAlert("ERROR WHEN DELETING A PATIENT [0006]", false, err);
    }
    setIsLoading(false);
  };

  return (
    <div className="w-2/3">
      {showPatientAddModal && (
        <ModalWith2Actions
          open={showPatientAddModal}
          setOpen={toggleShowPatientAddModal}
          addPerson={onCreateNewPatient}
          selectedPatient={patientEdit}
          formSetPatientEdit={formSetPatientEdit}
          setSelectedPatient={setPatientEdit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      <span ref={pIDRef} />

      {showPatientInfoModal && (
        <ModalWith2Actions
          open={showPatientInfoModal}
          setOpen={toggleShowPattientInfoModal}
          addPerson={editPatientSubmit}
          selectedPatient={patientEdit}
          formSetPatientEdit={formSetPatientEdit}
          setSelectedPatient={setPatientEdit}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}

      {showPatientViewModal && (
        <ModalPatientViewOnly
          selectedPatient={patientEdit}
          open={showPatientViewModal}
          setOpen={toggleShowPatientViewModal}
        />
      )}

      <ModalPatientStudy
        open={isAddStudy}
        setOpen={setIsAddStudy}
        patientId={patientEdit.id}
        study={studyTemp}
        setStudy={setStudyTemp}
        resetStudy={resetStudyTemp}
      />

      <HeaderTitle title="Patients List" />

      <div className="sm:flex sm:items-end  ">
        <div className="sm:flex-auto ">
          <div className="">
            <SearchInputForm
              onChangeValue={onChangeSearchFilter}
              value={searchValue}
            />
          </div>
        </div>
        <div className=" sm:mt-0 sm:ml-16 sm:flex-none ">
          <button
            onClick={addPerson}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Patient
          </button>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4  sm:-mx-6 lg:-mx-8 ">
          {/*//overflow-x-auto for X*/}
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 ">
            <div
              className="relative overflow-y-auto  shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
              style={{ height: 700 }}
            >
              {/*{selectedPeople.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    onClick={onDelete}
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                  >
                    Delete all
                  </button>
                </div>
              )}*/}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      DOB
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">View</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Add Study</span>
                    </th>
                  </tr>
                </thead>
                {!isLoading && people && peopleAPI && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {searchValue.length > 0
                      ? tableFilter.map((person, index) => (
                          <TableRow
                            key={index}
                            person={person}
                            setSelectedPeople={setSelectedPeople}
                            selectedPeople={selectedPeople}
                            onPatientEdit={onPatientEdit}
                            classNames={classNames}
                            onPatientView={onPatientView}
                            onAddStudy={onAddStudy}
                          />
                        ))
                      : people.map((person, index) => (
                          <TableRow
                            key={index}
                            person={person}
                            setSelectedPeople={setSelectedPeople}
                            selectedPeople={selectedPeople}
                            onPatientEdit={onPatientEdit}
                            onPatientView={onPatientView}
                            classNames={classNames}
                            onAddStudy={onAddStudy}
                          />
                        ))}
                  </tbody>
                )}
              </table>
              {(isLoading || people.length <= 0 || peopleAPI.length <= 0) && (
                <div className="w-100 flex justify-center">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
