import { useCallback, useEffect, useState } from "react";

let logoutTimer;
export const useAuthHook = () => {
  const [token, setToken] = useState(false);
  const [preloadedData, setPreloadedData] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((token, preloadedData, expirationDate) => {
    setPreloadedData(preloadedData);
    setToken(token);

    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(tokenExpirationDate);

    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: token,
        preloadedData: preloadedData,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    // setUserId(null);
    // setUserProfile(null);
    setPreloadedData(null);
    localStorage.removeItem("userData");
    window.location.reload();
  }, []);

  //Timeout of Token to Re-login
  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  //Saving Token  To Local Storage
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.token,
        storedData.preloadedData,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return { token, login, logout, preloadedData };
};
