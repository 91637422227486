import "./App.css";
import React, { useEffect, useState } from "react";
import IndexMainMenu from "./components/Main/IndexMainMenu";

import RoutesTable from "./PrivateRoute/RoutesTable";
import { AuthContext } from "./auth/useAuthContext";
import { useAuthHook } from "./auth/useAuthHook";
import Loader from "./components/LoaderOverlay/Loader";
import axios from "axios";
import ModalSubmitMessage from "./components/Modal/ModalSubmitMessage";
import Login from "./components/Login/Login";

export default function App() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { token, login, logout, preloadedData, isLoggedIn } = useAuthHook();

  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isArr, setIsArr] = useState(false);
  const [alertMode, setAlertMode] = useState("Notify");

  const globalAlert = (msg, isList, res, mode) => {
    if (!msg) return;
    setAlert(true);

    if (!res) setAlertMsg(msg);
    else setAlertMsg(`${msg}\n${res.response.data}`);
    setIsArr(isList);
  };


  return (
    <AuthContext.Provider
      value={{
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        preloadedData: preloadedData.studies, //, //"", //data.studies,
        medicationList: preloadedData.medicationList, //"", //data.medicationList,
        conditionsList: preloadedData.conditionsList, //"", //data.conditionsList,
        token,
        login,
        logout,
        setAlert: globalAlert,
        isLoggedIn: !!token,
      }}
    >
      {!token && (
        <div>
          <ModalSubmitMessage
            open={alert}
            setOpen={setAlert}
            data={alertMsg}
            isArr={isArr}
          />
          <Login />
        </div>
      )}
      {!!token && (
        <IndexMainMenu>
          {alert && (
            <ModalSubmitMessage
              open={alert}
              setOpen={setAlert}
              data={alertMsg}
              isArr={isArr}
            />
          )}

          <RoutesTable />
        </IndexMainMenu>
      )}
    </AuthContext.Provider>
  );
}
