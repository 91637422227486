import React, { useState, useContext } from "react";
import CustomPDFTable from "./CustomPDFTable";

import axios from "axios";
import Loader from "../LoaderOverlay/Loader";
import HeaderTitle from "../HeaderTitle";
import RepContainer from "./RepContainer";
import CustomBTNREport from "./CustomBTNREport";
import ObjHook from "../../hooks/ObjHook";
import StudyFilters from "./StudyFilters";
import { filterReportTemplate } from "../../assets/Utils_DataTemplate";
import { AuthContext } from "../../auth/useAuthContext";

export default () => {
  const { setAlert } = useContext(AuthContext);
  const [pdfData, setPDFData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //Filters For Submission
  const [filters, setFilters] = ObjHook(filterReportTemplate);
  const [filtersSID, setFiltersSID] = useState("");

  //TODO: OnEnter
  async function onShowPatientStudyReport() {
    setIsLoading(true);
    const width = ["18%", "13%", "14%", "31%", "6%", "18%"];
    //const width = ["16%", "11%", "40%", "12%", "21%"];
    const cols = ["Study Name", "Result", "Study ID", "Name", " Age", "Phone"]; //,
    const title = "Study Report by Study";
    const author = "Golden Research";

    const dataFilters = {
      ...filters,
      study_code: filtersSID,
    };

    try {
      const result = await axios.get("reports/studies-per-patients", {
        params: { dataFilters },
      });

      if (result.data.length > 0)
        setPDFData({width, cols, rows: result.data.sort(sortById), title, author});

      else
        setAlert(
          "No reports were found based on the search criteria. Please modify the filters or select a different study"
        );
    } catch (err) {
      setIsLoading(false);
      setAlert("Error when Getting Data [0007]", false, err);
      //alert("Error when Getting Data [0007]");
    }
    setIsLoading(false);
  }

  return (
    <div className="flex flex-col justify-center align-stretch px-12 w-2/3 mb-12 h-full">
      <HeaderTitle title="Reports" />
      <HeaderTitle>
        <RepContainer>
          <StudyFilters
            filters={filters}
            setFilters={setFilters}
            setFiltersSID={setFiltersSID}
          />
          <CustomBTNREport
            onClick={onShowPatientStudyReport}
            title="Generate Report"
          />
        </RepContainer>

        {isLoading && (
          <div className="w-full flex justify-center">
            <Loader />
          </div>
        )}
        {!isLoading &&
          pdfData &&
          pdfData.width &&
          pdfData.cols &&
          pdfData.rows && (
            <RepContainer>
              <CustomPDFTable
                width={pdfData.width}
                cols={pdfData.cols}
                rows={pdfData.rows}
                title={pdfData.title}
                author={pdfData.author}
              />
            </RepContainer>
          )}
      </HeaderTitle>
    </div>
  );
};


function sortById( a, b ) {
  if ( a.studyID < b.studyID ){
    return -1;
  }
  if ( a.studyID > b.studyID ){
    return 1;
  }
  return 0;
}
