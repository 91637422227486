import React from "react";
//patientInfo, setPatientInfo, validateForm,
export default ({ children, title }) => {
  return (
    <div>
      <div className="shadow sm:overflow-hidden sm:rounded-md ">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              <strong>{title}</strong>
            </h3>
          </div>

          <hr />
          <div className="grid grid-cols-6 gap-6">{children}</div>
        </div>
      </div>
    </div>
  );
};
