import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

export default ({
  label,
  placeholder,
  errMsg,
  value,
  onChangeValue,
  name,
  classNameDiv,
  classNameInput,
  maxLength,
  disabled,
}) => {
  const idSalt = "-xhxqo";
  const errClassName =
    "focus:ring-red-500 text-red-900 placeholder-red-300 focus:border-red-500 border-red-300";
  const defClassName = classNameInput
    ? classNameInput
    : "mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm";

  const invalid = /*value == "" || */ /*!valid ||*/ value.length === 0;
  return (
    <div className={classNameDiv ? classNameDiv : "col-span-6 sm:col-span-3"}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        type="date"
        name={name}
        id={label + idSalt}
        maxLength={maxLength ? maxLength : 40}
        autoComplete="given-name"
        className={invalid ? errClassName + " " + defClassName : defClassName}
        aria-invalid={invalid}
        aria-describedby={label + "-error" + idSalt}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        onChange={onChangeValue}
        disabled={disabled ? disabled : false}
      />
      {invalid && (
        <div
          className="flex flex-row justify-center items-center mt-1"
          aria-hidden="true"
        >
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
          <p className=" text-sm text-red-600" id={label + "-error" + idSalt}>
            {errMsg}
          </p>
        </div>
      )}
    </div>
  );
};
