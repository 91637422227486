import React from "react";
import {
  View,
  Text,
  Document,
  PDFViewer,
  Page,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

import DocLogo from "../../assets/img/ImagotipoV.jpg";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default ({ cols, rows, width, title, author }) => {
  const now = new Date().toString();

  return (
    <PDFViewer className="w-full" style={{ height: "100vh" }}>
      <Document>
        <Page
          size="LETTER"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            border: "10 solid #F5BA0B",
          }}
        >
          <View>
            <Text style={{ fontSize: 26, lineHeight: 1.5 }}>
              {rows ? rows[0].studyName : ""}
            </Text>
            <Text style={{ fontSize: 26 }}>CLINIC STUDY REPORT</Text>
          </View>
          <View>
            <Image src={DocLogo} />
          </View>
          <Text style={{ fontSize: 12 }}>{now}</Text>
        </Page>
        <Page
          size="LETTER"
          style={{
            padding: "0.5in 0.5in 0.5in 0.5in",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            /* border: "2px solid #F5BA0B",*/
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {(title || author) && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 1,
                  backgroundColor: "#F5BA0B",
                  padding: "6px 3px 6px 3px",
                  color: "#fff",
                  fontWeight: "900",
                }}
                wrap={false}
              >
                <Text>{title}</Text>
                <Text>{author}</Text>
              </View>
            )}

            <CustomHeader cols={cols} width={width} />
            <CustomBody rows={rows} width={width} />
          </View>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
            style={{
              textAlign: "center",
              fontSize: 10,
              color: "rgb(107 114 128)",

              marginTop: 5,
            }}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
};

const CustomBody = ({ rows, width }) => {
  let data = [];
  rows.map((transaction, index) => {
    let tempData = Object.keys(transaction).map(function (keyName, keyIndex) {
      //if (keyName !== "studyName")
      return (
        <CustomTD
          value={
            keyName === "age"
              ? getAge(transaction[keyName])
              : transaction[keyName]
          }
          width={width[keyIndex]}
          key={keyIndex}
        />
      );
    });

    return data.push(
      <View
        wrap={false}
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: `${index % 2 === 0 ? "#ffffff" : "#fffbeb"}`,
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {tempData}
      </View>
    );
  });
  return data;
};

const CustomTD = ({ value, width }) => {
  return (
    <Text
      wrap={false}
      style={{
        padding: "2px 3px 2px 4px",
        color: `${
          value === "positive" || value === "Positive"
            ? "#b91c1c"
            : value === "negative" || value === "Negative"
            ? "#15803d"
            : value === "invalid" || value === "Invalid"
            ? "#c2410c"
            : "#374151"
        }`,
        fontSize: "10px",
        width: width,
        textAlign: "center",
      }}
    >
      {value}
    </Text>
  );
};

const CustomHeader = ({ cols, width }) => {
  return (
    <View
      wrap={false}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {cols.map((item, index) => (
        <CustomTH value={item} width={width[index]} key={index} />
      ))}
    </View>
  );
};

const CustomTH = ({ value, width }) => {
  return (
    <Text
      wrap={false}
      style={{
        padding: "2px 3px 2px 4px",
        color: "#374151",
        fontSize: "12px",
        width: width,
        textAlign: "center",
        fontWeight: 900,
        backgroundColor: "#fef3c7",
      }}
    >
      {value}
    </Text>
  );
};

function getAge(age) {
  if (age) return Math.floor(dayjs(new Date()).diff(age, "year", true));
  else return 0;
}
